import React from "react";

import Img1 from "../assets/PlatformInfo/step1.png";
import Img2 from "../assets/PlatformInfo/step2.png";
import Img3 from "../assets/PlatformInfo/step3.png";
import Img4 from "../assets/PlatformInfo/step4.png";
import Img5 from "../assets/PlatformInfo/step5.png";
import Img1Mob from "../assets/PlatformInfo/img1_mob.jpg";
import Img2Mob from "../assets/PlatformInfo/img2_mob.jpg";
import Img3Mob from "../assets/PlatformInfo/img3_mob.jpg";
import Img4Mob from "../assets/PlatformInfo/img4_mob.jpg";
import Img5Mob from "../assets/PlatformInfo/img5_mob.jpg";

import classes from "./PlatformInfo.module.scss";
import Carousel from "./Carousel";
import { CarouselTypes } from "common";

const CarouselItems = [
  {
    item: Img1,
    itemMob: Img1Mob,
    label: "Poznáme, s čím vaše dítě zápasí",
    text: "Každé dítě je jedinečné a má své vlastní potřeby. Náš systém chytře sleduje, jak se vaše dítě učí a roste, a pomáhá odhalit oblasti, kde by mohlo potřebovat podporu. Možná si říkáte, že vše je v pořádku, ale i drobné výzvy, pokud zůstanou bez povšimnutí, mohou časem narůst. My jsme tu, abychom vám pomohli podchytit je včas.",
  },
  {
    item: Img2,
    itemMob: Img2Mob,
    label: "Zapojíme děti do učení",
    text: "Děti dnes tráví až příliš času pasivním sledováním obrazovek, což narušuje jejich schopnost soustředit se. My na to máme řešení! Náš systém děti motivuje k aktivnímu zapojení – učí je hrou a zábavnou formou rozvíjí jejich důležité dovednosti. Učení může být nejen smysluplné, ale i zábavné!",
  },
  {
    item: Img3,
    itemMob: Img3Mob,
    label: "Zapojíme nejen děti, ale i vás!",
    text: "Naše interaktivní lekce vám jednoduše a srozumitelně vysvětlí klíčové pojmy každého tématu. Díky tomu budete přesně vědět, jak nejlépe podpořit své dítě na jeho cestě k objevování a učení. Společně tvoříme skvělý tým!",
  },
  {
    item: Img4,
    itemMob: Img4Mob,
    label: "Budujeme pevné základy pro budoucí úspěch",
    text: "Zapomeňte na nekonečné seznamy úkolů nebo nudné opakování. Zaměřujeme se na rozvoj klíčových dovedností – abstraktního myšlení a paměti. Tyto pevné základy otevírají cestu k úspěchu nejen v matematice a jazycích, ale i v každodenním životě.",
  },
  {
    item: Img5,
    itemMob: Img5Mob,
    label: "Technologie není všechno",
    text: "Chápeme, jak důležitý je pro děti všestranný rozvoj – nejen učení, ale i socializace a každodenní interakce. Náš nástroj vašemu dítěti pomáhá získat náskok ve škole a rozvíjet klíčové dovednosti. Ale samotná technologie nestačí. Zapojení rodičů, podpora mateřských škol a učitelů jsou stejně důležité pro to, aby děti mohly růst a uspět.",
  },
];

export const PlatformInfo = () => {
  return (
    <section className={classes.platformInfo}>
      <div className={classes.headerContainer}>
        <h2 className={classes.platformInfoHeader}>
          Proč zvolit výuku se Sharp Brain?
        </h2>
      </div>

      <div className={classes.platformInfoSlider}>
        <Carousel
          slides={CarouselItems}
          hasControls
          hasIndicators
          type={CarouselTypes.TextImages}
          defSlide={0}
        />
      </div>
    </section>
  );
};
