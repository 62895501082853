import classNames from "classnames";
import React, { useCallback, useEffect, useState } from "react";
import useEmblaCarousel from "embla-carousel-react";
import { CarouselIndicator, useIndicator } from "./CarouselIndicator";
import { EmblaCarouselType } from "embla-carousel";

import { ReactComponent as NextBtn } from "../assets/Features/Carousel/carousel-right.svg";
import { ReactComponent as PrevBtn } from "../assets/Features/Carousel/carousel-left.svg";
import classes from "./Carousel.module.scss";
import { CarouselTypes } from "common";

interface ICarouselItem {
  item: string;
  itemMob?: string;
  label: string;
  text?: string;
}

interface ISlides {
  slides: ICarouselItem[] | React.ReactNode[];
  hasControls?: boolean;
  hasIndicators?: boolean;
  type: string;
  defSlide: number;
}

const Carousel = ({
  slides,
  hasControls,
  hasIndicators,
  type,
  defSlide,
}: ISlides) => {
  const options = { startIndex: defSlide };
  const [emblaRef, emblaApi] = useEmblaCarousel(options);
  const [prevBtnDisabled, setPrevBtnDisabled] = useState(true);
  const [nextBtnDisabled, setNextBtnDisabled] = useState(true);

  const { selectedIndex, onIndicatorClick } = useIndicator(emblaApi);

  const scrollPrev = useCallback(() => {
    emblaApi?.scrollPrev();
  }, [emblaApi]);

  const scrollNext = useCallback(() => {
    emblaApi?.scrollNext();
  }, [emblaApi]);

  const onSelect = useCallback((emblaApi: EmblaCarouselType) => {
    setPrevBtnDisabled(!emblaApi.canScrollPrev());
    setNextBtnDisabled(!emblaApi.canScrollNext());
  }, []);

  useEffect(() => {
    if (!emblaApi) {
      return;
    }

    onSelect(emblaApi);
    emblaApi.on("reInit", onSelect);
    emblaApi.on("select", onSelect);
  }, [emblaApi, onSelect]);

  const isCarouselItem = (
    slide: ICarouselItem | React.ReactNode,
  ): slide is ICarouselItem => {
    return typeof slide === "object" && "item" in slide! && "label" in slide;
  };

  return (
    <div
      className={classNames(classes.carousel, {
        [classes.images]: type === CarouselTypes.Images,
        [classes.mobile]: type === CarouselTypes.Mobile,
        [classes.textImages]: type === CarouselTypes.TextImages,
      })}
    >
      <div className={classes.carouselContainer} ref={emblaRef}>
        <div className={classes.contentContainer}>
          {slides.map((slide, index) => (
            <div
              className={classNames(classes.slide, {
                [classes.notSnapped]: index !== selectedIndex,
              })}
              key={index}
            >
              {isCarouselItem(slide) && type === CarouselTypes.Images && (
                <>
                  <img
                    className={classes.slideImg}
                    src={slide.item}
                    alt={slide.label}
                  />
                  <span className={classes.slideText}>{slide.label}</span>
                </>
              )}

              {!isCarouselItem(slide) && type === CarouselTypes.Mobile && slide}

              {isCarouselItem(slide) && type === CarouselTypes.TextImages && (
                <div className={classes.infoSlide}>
                  <div className={classes.slideTextBox}>
                    <p
                      className={classNames(classes.slideNumber, {
                        [classes.slideNumber2]: index === 1,
                        [classes.slideNumber3]: index === 2,
                        [classes.slideNumber4]: index === 3,
                        [classes.slideNumber5]: index === 4,
                      })}
                    >
                      0{index + 1}
                    </p>
                    <h3 className={classes.slideTitle}>{slide.label}</h3>
                    <p className={classes.slideDescription}>{slide.text}</p>
                  </div>
                  <div
                    className={classNames(classes.slideImgBox, {
                      [classes.slideImgBox1]: index === 0,
                      [classes.slideImgBox2]: index === 1,
                      [classes.slideImgBox3]: index === 2,
                      [classes.slideImgBox4]: index === 3,
                      [classes.slideImgBox5]: index === 4,
                    })}
                  >
                    <picture>
                      <source
                        media="(max-width: 700px)"
                        srcSet={slide.itemMob}
                      />
                      <img
                        className={classes.slideImg}
                        src={slide.item}
                        alt=""
                      />
                    </picture>
                  </div>
                </div>
              )}
            </div>
          ))}
        </div>

        {hasIndicators && (
          <div className={classes.carouselIndicator}>
            {slides.map((_, index) => (
              <CarouselIndicator
                key={index}
                onClick={() => onIndicatorClick(index)}
                className={classNames(classes.carouselIndicatorItem, {
                  [classes.carouselIndicatorActive]: index === selectedIndex,
                })}
              />
            ))}
          </div>
        )}
      </div>
      {hasControls && (
        <div className={classes.btnContainer}>
          <button
            className={classNames(classes.prevBtn, {
              [classes.disabledBtn]: prevBtnDisabled,
            })}
            onClick={scrollPrev}
          >
            <PrevBtn />
          </button>
          <button
            className={classNames(classes.nextBtn, {
              [classes.disabledBtn]: nextBtnDisabled,
            })}
            onClick={scrollNext}
          >
            <NextBtn />
          </button>
        </div>
      )}
    </div>
  );
};

export default Carousel;
