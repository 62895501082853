import isMobile from "is-mobile";
import React, { useState } from "react";
import { NavLink } from "react-router-dom";

import Carousel from "./Carousel";
import InfoIcon from "../assets/info.svg";
import FirstDiamond from "../assets/month-diamond.svg";
import SecondDiamond from "../assets/half_year-diamond.svg";
import classNames from "classnames";
import classes from "./Prices.module.scss";
import SmartLook from "smartlook-client";
import { CarouselTypes, SubscriptionPlanTypes } from "common";
import { Button } from "primereact/button";

const mobileDevice = isMobile({ tablet: false, featureDetect: true });

const tooltipTextMapping: Record<string, string> = {
  "1": "Máme obrovskou databázi úkolů na nejrůznější témata, kterou neustále rozšiřujeme. Náš systém vybírá ty nejlepší aktivity, aby vaše dítě mohlo rozvíjet své dovednosti co nejefektivněji – hravě a smysluplně.",
  "2": "Náš algoritmus je postaven na více než 40 letech pedagogických zkušeností. Sledujeme, co a jak vaše dítě dělá, a vytváříme profil, který pomáhá odhalit skryté výzvy. Díky tomu sestavíme hodiny přesně tak, aby vašemu dítěti co nejlépe seděly a pomohly růst.",
  "3": "Teorii vysvětlíme jednoduše a přehledně – nejen pro vaše dítě, ale i pro vás. Žádné složité poučky nebo nuda, ale jasně a na pohodu, jako by vám to vysvětloval oblíbený učitel.",
  "4": "Náš systém se snadno ovládá a zároveň vám poskytne nepřetržitý přehled o tom, jak vaše dítě postupuje a jaké dělá pokroky. Učení ještě nikdy nebylo tak přehledné!",
  "5": "Tohle nejsou jen obyčejné hry! Neustále přidáváme nové minihry, které děti aktivně zapojují a pomáhají jim zvládat náročné oblasti, se kterými se dnes nejčastěji potýkají.",
  "6": "Víme, jak je těžké udržet dnešní děti motivované k učení. Proto jsme vytvořili zábavné odměny – animované tematické sbírky, které děti milují.",
  "7": "Tady zjistíte silné i slabé stránky vašeho dítěte, abyste přesně pochopili jeho vzdělávací potřeby.",
};

const Prices = ({ hasButton }: { hasButton: boolean }) => {
  const [tooltipText, setTooltipText] = useState<string | null>(null);
  const registration = () => {
    SmartLook.navigation("/register");
    window.location.href = process.env.REACT_APP_REGISTRATION_URL as string;
  };

  const handleMouseEnter = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    const id = event.currentTarget.closest("li")?.getAttribute("id");

    if (id && tooltipTextMapping[id]) {
      setTooltipText(tooltipTextMapping[id]);
    }
  };

  const handleMouseLeave = () => {
    setTooltipText(null);
  };

  const renderSubscriptionPlans = () => [
    <article
      className={classNames(
        classes.subscriptionPlan,
        classes.subscriptionPlanFirst,
      )}
      key={SubscriptionPlanTypes.Standard}
    >
      <img className={classes.subscriptionPlanBg} src={FirstDiamond} alt="" />
      <h2 className={classes.subscriptionPlanHeader}>1 měsíc</h2>
      <p className={classes.subscriptionPlanPrice}>{199} Kč</p>
      <p className={classes.subscriptionPlanTerm}>
        Cena je uvedena za 1 účastníka
      </p>
      <div className={classes.line}></div>
      <ul className={classes.subscriptionPlanList}>
        <li id="1">
          10000+ úkolů
          <Button
            tooltip={tooltipText || " "}
            className={classes.infoIcon}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            tooltipOptions={{ position: "bottom", className: "custom-tooltip" }}
          >
            <img src={InfoIcon} alt="Info" />
          </Button>
        </li>
        <li id="2">
          Personalizační algoritmus
          <Button
            tooltip={tooltipText || " "}
            className={classes.infoIcon}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            tooltipOptions={{ position: "bottom", className: "custom-tooltip" }}
          >
            <img src={InfoIcon} alt="Info" />
          </Button>
        </li>
        <li id="3">
          Animované lekce ke každému tématu
          <Button
            tooltip={tooltipText || " "}
            className={classes.infoIcon}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            tooltipOptions={{ position: "bottom", className: "custom-tooltip" }}
          >
            <img src={InfoIcon} alt="Info" />
          </Button>
        </li>
        <li id="4">
          Sekce “Pro rodiče”
          <Button
            tooltip={tooltipText || " "}
            className={classes.infoIcon}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            tooltipOptions={{ position: "bottom", className: "custom-tooltip" }}
          >
            <img src={InfoIcon} alt="Info" />
          </Button>
        </li>
        <li id="5">
          Mini-hry
          <Button
            tooltip={tooltipText || " "}
            className={classes.infoIcon}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            tooltipOptions={{ position: "bottom", className: "custom-tooltip" }}
          >
            <img src={InfoIcon} alt="Info" />
          </Button>
        </li>
        <li id="6">
          Tematické sbírky
          <Button
            tooltip={tooltipText || " "}
            className={classes.infoIcon}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            tooltipOptions={{ position: "bottom", className: "custom-tooltip" }}
          >
            <img src={InfoIcon} alt="Info" />
          </Button>
        </li>
      </ul>
      <a
        href="#"
        onClick={registration}
        className={classes.subscriptionPlanBtn}
      >
        Vybrat
      </a>
    </article>,
    <article
      className={classNames(
        classes.subscriptionPlan,
        classes.subscriptionPlanSecond,
      )}
      key={SubscriptionPlanTypes.Premium}
    >
      <img className={classes.subscriptionPlanBg} src={SecondDiamond} alt="" />
      <span className={classes.subscriptionPlanDiscount}>Sleva 33%</span>
      <h2 className={classes.subscriptionPlanHeader}>3 měsíce</h2>
      <p className={classes.subscriptionPlanPrice}>
        <span className={classes.subscriptionPlanPriceOld}>597</span>
        399 Kč
      </p>
      <p className={classes.subscriptionPlanTerm}>
        Cena je uvedena za 1 účastníka
      </p>
      <div className={classes.line}></div>
      <ul className={classes.subscriptionPlanList}>
        <li id="1">
          10000+ úkolů
          <Button
            tooltip={tooltipText || " "}
            className={classes.infoIcon}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            tooltipOptions={{ position: "bottom", className: "custom-tooltip" }}
          >
            <img src={InfoIcon} alt="Info" />
          </Button>
        </li>
        <li id="2">
          Personalizační algoritmus
          <Button
            tooltip={tooltipText || " "}
            className={classes.infoIcon}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            tooltipOptions={{ position: "bottom", className: "custom-tooltip" }}
          >
            <img src={InfoIcon} alt="Info" />
          </Button>
        </li>
        <li id="3">
          Animované lekce ke každému tématu
          <Button
            tooltip={tooltipText || " "}
            className={classes.infoIcon}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            tooltipOptions={{ position: "bottom", className: "custom-tooltip" }}
          >
            <img src={InfoIcon} alt="Info" />
          </Button>
        </li>
        <li id="4">
          Sekce “Pro rodiče”
          <Button
            tooltip={tooltipText || " "}
            className={classes.infoIcon}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            tooltipOptions={{ position: "bottom", className: "custom-tooltip" }}
          >
            <img src={InfoIcon} alt="Info" />
          </Button>
        </li>
        <li id="5">
          Mini-hry
          <Button
            tooltip={tooltipText || " "}
            className={classes.infoIcon}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            tooltipOptions={{ position: "bottom", className: "custom-tooltip" }}
          >
            <img src={InfoIcon} alt="Info" />
          </Button>
        </li>
        <li id="6">
          Tematické sbírky
          <Button
            tooltip={tooltipText || " "}
            className={classes.infoIcon}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            tooltipOptions={{ position: "bottom", className: "custom-tooltip" }}
          >
            <img src={InfoIcon} alt="Info" />
          </Button>
        </li>
      </ul>
      <a
        href="#"
        onClick={registration}
        className={classes.subscriptionPlanBtn}
      >
        Vybrat
      </a>
    </article>,
  ];

  return (
    <section className={classes.pricePage}>
      <h2 className={classes.pricePageHeader}>Naše balíčky</h2>
      <div className={classes.pricePageCards}>
        {mobileDevice ? (
          <Carousel
            slides={renderSubscriptionPlans()}
            type={CarouselTypes.Mobile}
            hasControls
            defSlide={0}
          />
        ) : (
          renderSubscriptionPlans()
        )}
      </div>
      {hasButton && (
        <NavLink to="/">
          <button className={classes.pricePageBtn}>Hlavní stránka</button>
        </NavLink>
      )}
    </section>
  );
};

export default Prices;
